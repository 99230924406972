
import { mixins, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import QForm from 'quasar/src/components/form/QForm.js';

import WorkflowMixin from './mixins/WorkflowMixin.vue'
import logging from '@/utils/logging'
import { IWorkflow, WorkflowModel } from '@/components/workflow/workflow-model'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class WorkflowFormModal extends mixins(WorkflowMixin) {
  @Prop({ default: {} })
  workflowData!: WorkflowModel

  @Prop()
  modalVisible!: boolean

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get workflowId() {
    return this.$route.params.workflowId
  }

  requiredRule = {
    message: 'This field is required',
  }

  form: IWorkflow = {
    title: '',
    description: '',
  }

  rules = {
    title: [this.requiredRule],
  }

  get loading() {
    return this.$store.state.project.loading
  }

  get isNew() {
    return !this.workflowData?._id
  }

  get modalTitle() {
    return this.isNew ? 'Create new workflow' : 'Edit workflow'
  }

  onSubmitWorkflow() {
    const form = this.$refs.formRef as QForm
    form
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }
        await this.doSaveWorkflow()
        this.onCancel()
      })
      .catch((error: unknown) => {
        logging.debug(error)
      })
  }

  async doSaveWorkflow() {
    if (this.isNew) {
      // [WorkflowMixin]
      return this.addWorkflow(this.form)
    } else {
      // [WorkflowMixin]
      return this.updateWorkflow(this.form)
    }
  }

  onCancel() {
    this.visible = false
  }

  async created() {
    if (this.workflowData?._id) {
      this.form = { ...this.form, ...this.workflowData.sertialize() }
    }
  }
}
