<template>
  <section class="q-pa-md">
    <div class="row no-wrap">
      <div>
        <h1 class="text-h4">{{ workspace.title }}</h1>
        <p class="text-subtitle2">Workspace workflows setting</p>
      </div>
    </div>

    <div class="container-smaller q-mt-lg">
      <q-list bordered separator class="rounded-borders bg-grey-1">
        <q-item clickable v-ripple v-for="workflow in workflows" :key="workflow._id">
          <q-item-section class="text-subtitle2 text-blue" @click="gotoWorkflowStateSetting(workflow)">
            {{ workflow.title }}
          </q-item-section>
          <q-item-section side>
            <div class="q-gutter-sm">
              <q-btn size="sm" round icon="edit" @click="onEdit(workflow)" />
              <q-btn size="sm" round color="red" icon="delete" @click="onDelete(workflow)" />
            </div>
          </q-item-section>
        </q-item>
      </q-list>

      <q-space class="q-my-md" />

      <q-btn color="primary" icon="add" @click.prevent="modalWorkflowFormVisible = true" label="New workflow" />
    </div>
  </section>
  <WorkflowFormModal
    v-if="modalWorkflowFormVisible"
    :workflowData="selected"
    :workspace="workspace"
    :modalVisible="modalWorkflowFormVisible"
    @update:closeModal="onCloseModal"
  />
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'

import { maska } from 'maska'
import { WORKFLOW_TYPE } from '@/constants/vars'

import NotFound from '@/components/common/NotFound.vue'
import WorkflowFormModal from '@/components/workflow/WorkflowFormModal.vue'
import WorkflowMixin from '@/components/workflow/mixins/WorkflowMixin.vue'
import { WorkspaceModel } from '@/components/workspace/workspace-model'
import { WorkflowModel } from '@/components/workflow/workflow-model'

@Options({
  components: {
    NotFound,
    WorkflowFormModal,
  },
  directives: { maska },
})
export default class WorkflowsSetting extends mixins(WorkflowMixin) {
  modalWorkflowFormVisible = false
  selected: WorkflowModel | null = null

  WORKFLOW_TYPE = WORKFLOW_TYPE

  get workspaceId() {
    return this.$route.params.workspaceId
  }

  get workspace(): WorkspaceModel {
    return this.$store.getters.workspace || {}
  }

  get workflows(): WorkflowModel[] {
    return this.$store.getters.workflows(this.workspaceId) || []
  }

  async onDelete(workflow: WorkflowModel) {
    this.$q
      .dialog({
        title: 'Confirm',
        message: 'Are you sure you want to delete?',
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        await this.removeWorkflow(workflow.sertialize())
      })
  }

  onEdit(workflow: WorkflowModel) {
    this.modalWorkflowFormVisible = true
    this.selected = workflow
  }

  onCloseModal() {
    this.modalWorkflowFormVisible = false
    this.selected = null
  }

  gotoWorkflowStateSetting(workflow: WorkflowModel) {
    this.$router.push({
      name: 'workspace_board.workflow_states',
      params: {
        workflowId: workflow._id || '',
      },
    })
  }

  async created() {
    this.$meta.setMeta({ title: ['Workflows setting'] })
    await this.getWorkflows()
  }
}
</script>
