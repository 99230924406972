<template>
  <q-dialog v-model="visible">
    <q-card style="width: 500px">
      <q-toolbar>
        <q-toolbar-title>
          {{ modalTitle }}
        </q-toolbar-title>

        <q-btn flat round dense icon="close" v-close-popup />
      </q-toolbar>
      <q-card-section>
        <q-form ref="formRef">
          <label class="text-grey-9 text-bold">Workflow name</label>
          <q-input
            lazy-rules
            :rules="[val => (val && val.length > 0) || requiredRule.message]"
            outlined
            v-model="form.title"
            dense
            placeholder="workflow name"
          />

          <q-space class="q-my-lg" />

          <label class="text-grey-9 text-bold">Description</label>
          <q-input type="textarea" outlined v-model="form.description" dense placeholder="workflow description" />
          <q-space class="q-my-md" />

          <div class="q-gutter-sm text-right">
            <q-btn color="white" text-color="black" @click="onCancel">Cancel</q-btn>
            <q-btn color="primary" @click="onSubmitWorkflow"> Save </q-btn>
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { QForm } from 'quasar'

import WorkflowMixin from './mixins/WorkflowMixin.vue'
import logging from '@/utils/logging'
import { IWorkflow, WorkflowModel } from '@/components/workflow/workflow-model'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class WorkflowFormModal extends mixins(WorkflowMixin) {
  @Prop({ default: {} })
  workflowData!: WorkflowModel

  @Prop()
  modalVisible!: boolean

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get workflowId() {
    return this.$route.params.workflowId
  }

  requiredRule = {
    message: 'This field is required',
  }

  form: IWorkflow = {
    title: '',
    description: '',
  }

  rules = {
    title: [this.requiredRule],
  }

  get loading() {
    return this.$store.state.project.loading
  }

  get isNew() {
    return !this.workflowData?._id
  }

  get modalTitle() {
    return this.isNew ? 'Create new workflow' : 'Edit workflow'
  }

  onSubmitWorkflow() {
    const form = this.$refs.formRef as QForm
    form
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }
        await this.doSaveWorkflow()
        this.onCancel()
      })
      .catch((error: unknown) => {
        logging.debug(error)
      })
  }

  async doSaveWorkflow() {
    if (this.isNew) {
      // [WorkflowMixin]
      return this.addWorkflow(this.form)
    } else {
      // [WorkflowMixin]
      return this.updateWorkflow(this.form)
    }
  }

  onCancel() {
    this.visible = false
  }

  async created() {
    if (this.workflowData?._id) {
      this.form = { ...this.form, ...this.workflowData.sertialize() }
    }
  }
}
</script>
