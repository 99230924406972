
import { Options, mixins } from 'vue-class-component'

import { maska } from 'maska'
import { WORKFLOW_TYPE } from '@/constants/vars'

import NotFound from '@/components/common/NotFound.vue'
import WorkflowFormModal from '@/components/workflow/WorkflowFormModal.vue'
import WorkflowMixin from '@/components/workflow/mixins/WorkflowMixin.vue'
import { WorkspaceModel } from '@/components/workspace/workspace-model'
import { WorkflowModel } from '@/components/workflow/workflow-model'

@Options({
  components: {
    NotFound,
    WorkflowFormModal,
  },
  directives: { maska },
})
export default class WorkflowsSetting extends mixins(WorkflowMixin) {
  modalWorkflowFormVisible = false
  selected: WorkflowModel | null = null

  WORKFLOW_TYPE = WORKFLOW_TYPE

  get workspaceId() {
    return this.$route.params.workspaceId
  }

  get workspace(): WorkspaceModel {
    return this.$store.getters.workspace || {}
  }

  get workflows(): WorkflowModel[] {
    return this.$store.getters.workflows(this.workspaceId) || []
  }

  async onDelete(workflow: WorkflowModel) {
    this.$q
      .dialog({
        title: 'Confirm',
        message: 'Are you sure you want to delete?',
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        await this.removeWorkflow(workflow.sertialize())
      })
  }

  onEdit(workflow: WorkflowModel) {
    this.modalWorkflowFormVisible = true
    this.selected = workflow
  }

  onCloseModal() {
    this.modalWorkflowFormVisible = false
    this.selected = null
  }

  gotoWorkflowStateSetting(workflow: WorkflowModel) {
    this.$router.push({
      name: 'workspace_board.workflow_states',
      params: {
        workflowId: workflow._id || '',
      },
    })
  }

  async created() {
    this.$meta.setMeta({ title: ['Workflows setting'] })
    await this.getWorkflows()
  }
}
